import React from "react"

const NotFoundPage = () => {
  return (
    <div>
      <h1>404: Page not found</h1>
    </div>
  )
}

export default NotFoundPage
